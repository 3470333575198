import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { primary } from 'modules/app/styles/CtaButton.styles';
import { config } from 'style/grids.config';
import { breakpoints } from 'style/variables';

export const root = css`
  width: 100%;
  background: hsl(var(--color-grayscale-9));
  padding: 100px 20px;

  @media ${breakpoints.medium} {
    padding: 160px 64px 100px;
  }
`;

export const content = css`
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));

  @media ${breakpoints.medium} {
    ${config.base};
  }
`;

export const subTitle = css`
  margin-bottom: 20px;
  ${font.size.tiny};
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight: normal;

  @media ${breakpoints.medium} {
    grid-column: 1 / span 6;
  }
`;

export const title = css`
  ${font.size.xxLarge};
  ${font.family.secondary};
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 60px;

  @media ${breakpoints.medium} {
    grid-column: 1 / span 9;
    ${font.size.huge};
  }
`;

export const icon = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
  border: 1px solid hsl(var(--color-borderDark-9));
  border-radius: 50%;
`;

export const listItems = css`
  grid-column: 1 / span 12;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  list-style: none;
  padding: 0;
  margin: 0 0 172px;

  li {
    width: 304px;
    ${font.size.medium};
    color: hsl(var(--color-grayscale-1));
  }
`;

export const imageWrapper = css`
  position: relative;
  overflow: hidden;
  min-width: 100%;
  display: flex;
  margin-bottom: 40px;

  @media ${breakpoints.medium} {
    grid-column: 1 / span 5;
    margin-bottom: 0;
  }
`;

export const image = css`
  width: 100%;
`;

export const titleSpecial = css`
  ${font.family.secondary};
  ${font.size.xxLarge};
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;

  @media ${breakpoints.medium} {
    font-size: 60px;
    line-height: 58px;
    letter-spacing: -1px;
  }
`;

export const textWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media ${breakpoints.medium} {
    grid-column: 7 / span 5;
  }
`;

export const description = css`
  ${font.size.large};
`;

export const buttonWrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

export const details = css`
  font-size: 14px;
  line-height: calc(var(--unit) * 5);

  span {
    ${font.weight.bold};
    text-transform: uppercase;
  }
`;

export const link = css`
  ${primary};

  background: hsl(var(--color-robin-2));
  color: hsl(var(--color-text-1));

  &::before {
    background-color: hsl(var(--color-grayscale-3));
  }

  &:hover,
  &:active {
    color: hsl(var(--color-text-1));
  }
`;
